import React, { Component } from 'react';

import './index.scss'

class Secondary extends Component  {
  render() {
    return (
      <div className='Secondary'>
        <div className='Secondary__header'>
          <img src={require('../../assets/images/secondary/logo.webp')} width='47%' height='48' />
          <a target="_blank" href='https://www.dspnsr.st/shop/collections/4pbefic/assets/c2jvf6m' className='Secondary__header__button'>visit minting page</a>
        </div>
        <div><img src='https://i.seadn.io/gcs/files/d8078970f0205f0b33354b07f6b897e3.jpg?auto=format&dpr=1&w=600' /></div>
        <div className='Secondary__details'>
          <div className='Secondary__details__header'>The Secondary</div>
          <div className='Secondary__details__subheader'><i>$STCL The Secondary</i></div>
          <div className='Secondary__details__description'>The Secondary from Satoshi's Closet. Limited edition of 50.</div>
          <div className='Secondary__details__row'>
            <img src={require('../../assets/images/secondary/created-on-ethereum.webp')} />
            <div><span>Created on:</span> August 25, 2023</div>
          </div>
          <div className='Secondary__details__row'>
            <img src={require('../../assets/images/secondary/edition-size.webp')} />
            <div><span>Edition size:</span> 50 (X minted)</div>
          </div>
          <div className='Secondary__details__row'>
            <img src={require('../../assets/images/secondary/contract-address.webp')} />
            <div><span>Contract address:</span> 0x952...492e (<a target='_blank' href='https://etherscan.io/address/0x95207e061a302f1cfd0907c51b2caaba8b0a492e'>View on Etherscan</a>)</div>
          </div>
        </div>
        <div className='Secondary__header mobile'>
          <a target="_blank" href='https://www.dspnsr.st/shop/collections/4pbefic/assets/c2jvf6m' className='Secondary__header__button'>visit minting page</a>
        </div>
        <div className='Secondary__footer'>&#169; Satoshi's Closet 2023</div>
      </div>
    )
  }
}

export default Secondary
