import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import Header from '../shared/Header'
import Footer from '../shared/Footer'

import './index.scss';

const CONTRACT_ADDRESS = '0x7abe4b30A1f61c02e43b17515B266736Dd938Dd7';

class Token extends Component {
  state = {}

  componentWillMount() {
    this.getTokenInfo();
  }

  componentDidMount() {
      document.title = 'Satoshi\'s Closet ISO Shirt';
  }

  async getTokenInfo() {
    const token_id = this.props.match.params.token_id;
    const url = 'https://api.opensea.io/api/v1/assets/?asset_contract_address=' + CONTRACT_ADDRESS + '&token_ids=' + token_id;
    const self = this;
    fetch(url).then(resp => resp.json()).then(data => {
      self.setState({asset: data.assets[0]})
    });
  }

  render() {
    const { asset } = this.state;
    const token_id = this.props.match.params.token_id;

    if (token_id < 1 || token_id > 50) {
        return <Redirect to='/iso' />
    }

    const item = {
      name: 'The Initial Shirt Offering',
      etherscan_url: 'https://etherscan.io/token/' + CONTRACT_ADDRESS + '?a=' + token_id,
    }
    console.log(asset)
    return (
        <div>
            <Header/>
            <div className='item iso_token'>
                <div className='item__inner'>
                    <div className='item__image iso_token'>
                        <img alt="Satoshi's Closet ISO Shirt" src={require('../../assets/images/iso_shirt.png')} />
                    </div>
                    <div className='details'>
                        <div className='details__name'>{item.name}</div>
                        <div><span>TOKEN ID:</span> {token_id}</div>
                        <div><span>TOTAL SUPPLY:</span> 50</div>
                        <div><span>CONTRACT:</span> {CONTRACT_ADDRESS}</div>
                        <div><span>OWNER:</span> {asset ? asset.owner.address : 'RETRIEVING DATA...'}</div>

                        <div className='details__link'>
                            <a className='link' target='_blank' rel="noopener noreferrer" href={item.etherscan_url}>View on Etherscan</a>
                        </div>
                        {/*<div className='qr_code_container'>
                            <img height={84} width={84} alt="Satoshi's Closet Tom's Shirt QR Code" src={require('../../assets/images/tom_shirt_qr.png')} />
                        </div>*/}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
  }
}

export default Token
